.site-header {
    position: relative;
    padding: 24px 0;
	z-index: 2;

	&::before {
		content: '';
		position: absolute;
		top: -140px;
		right: -100px;
		width: 1440px;
		height: 324px;
		background-image: url('./../../images/header-illustration-light.svg');

		.lights-off & {
			background-image: url('./../../images/header-illustration-dark.svg');
		}
	}
}

.site-header-inner {
    position: relative; /* To display all elements above the background color */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-links {
    display: inline-flex;

    li {
        display: inline-flex;
    }

    a:not(.button) {
        @include font-size(7, mobile, true, true, true);
        @if ( get-font-size(7, desktop) != get-font-size(7, mobile) ) {
            @include media( '>medium' ) {
                @include font-size(7, desktop, true, true, true);
            }
        }
        @include font-weight(semibold);
        @include anchor-aspect(header);
        line-height: 16px;
        padding: 8px 24px;
    }
}

@include media( '>medium' ) {

	.site-header {

		&::before {
			display: none;
		}
	}
}
